.button {
  width: 100%;
  display: flex;
  justify-items: flex-start;
  align-items: center;
  border: 2px solid black;
  border-radius: 100px;
  margin-bottom: 20px;
}

.button:hover {
  cursor: pointer;
  background: black;
  color: white;
}

.button:hover .appleIcon img {
  color: white;
}

.link {
  border: none;
  background: none;
}

.centerContent {
  margin: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.footerLogo {
  bottom: 0;
  left: 0;
  margin: 0 auto 30px;
  position: absolute;
  right: 0;
  text-align: center;
}

.smallIcon {
  margin-left: 5px;
  margin-right: 10px;
  margin-top: -3px;
  display: inline-block;
}

.smallIcon img {
  width: 100% !important;
}

@media (max-height: 991px) {
  .spacer {
    height: 60px;
  }
}
@media (max-height: 767px) {
  .spacer {
    height: 40px;
  }
}
@media (max-height: 600px) {
  .footerLogo {
    position: relative;
    padding-top: 50px;
  }
  .centerContent {
    position: relative;
    transform: unset;
    left: unset;
    top: unset;
    padding: 40px 10px 0 10px;
  }
}
@media (max-height: 650px) {
  .spacer {
    height: 30px;
  }
}
@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
