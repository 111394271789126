.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
.icon {
  width: 42px;
  height: 42px;
  border-width: 2px !important;
  padding: 2px;
}

.appleIcon {
  margin-top: -1px;
  margin-left: -1px;
}
