.fullscreenSpinner {
  position: absolute;
  z-index: 3;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: inherit;
  height: 100vh;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
}
