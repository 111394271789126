.spacer {
  height: 80px;
}
.input {
  background: #f3f3f3;
  border-radius: 99px;
  border: none;
  padding: 15px;
  font-size: 16px;
}
.link {
  border: none;
  background: none;
}
.button {
  background: #f3f3f3;
  border-radius: 100px;
  border: none;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
  width: 100%;
}
.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-left: 15%;
  padding-right: 15%;
  margin: 0 auto;
}
.footerAnimate {
  text-align: center;
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
  left: 0;
  animation: animateUp 0.3s linear;
  transition: 0.3s ease-in-out;
}
.label {
  text-transform: uppercase;
  color: #afafaf;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
}
.footer {
  padding-bottom: 50px;
  padding-top: 50px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.error {
  color: #ee7584;
}

.footerWhite {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: unset;
  z-index: 3;
  animation: animateUp 0.5s linear;
  transition: 0.5s ease-in-out;
}
.footerInner {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 15px;
  background-color: #fff;
  z-index: 3;
}
.bottomButtons {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
}
.overlay {
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding: 0;
  z-index: 2;
}
.bringContentDown {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: inherit;
  bottom: 30px;
}
.centerContent {
  margin: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.footerLogo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto 30px auto;
  text-align: center;
}

@media (max-height: 991px) {
  .spacer {
    height: 60px;
  }
}
@media (max-height: 767px) {
  .spacer {
    height: 40px;
  }
}
@media (max-height: 600px) {
  .footerLogo {
    position: relative;
    padding-top: 50px;
  }
  .centerContent {
    position: relative;
    transform: unset;
    left: unset;
    top: unset;
    padding: 40px 10px 0 10px;
  }
}

@media (max-height: 650px) {
  .spacer {
    height: 30px;
  }
}
@keyframes animateUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
