/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 24px;
  left: 8px;
  padding: 4px;
  top: 8px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  position: fixed !important;
  right: 15px !important;
  top: 15px !important;
  width: 12px !important;
  height: 12px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.bm-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bm-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
  text-align: center;
}

.bm-item {
  display: inline-block;
  padding: 10px 0;
}

.menu-item {
  font-size: 18px;
  font-weight: 800;
  border: none;
  background: none;
  display: block;
  width: 100%;
}

/* Styling of overlay */
.bm-overlay {
  background: #fff;
}

/* footer */
.footer {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  padding: 30px 12px;
  background-color: #fff;
  margin: 0 auto;
}

@media (max-height: 680px) {
  .footer {
    position: relative;
    padding: 10px 0 0 0;
    width: calc(100% + 60px);
    left: -30px;
  }
}
