.cardBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 100%;
}
.cardBodyText {
  width: calc(100% - 30px);
  float: left;
  margin: 0 15px 0 0;
}
.deleteDoc {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.crossIcon {
  height: 10px;
}
.checkIcon {
  height: 6px;
  filter: invert(64%) sepia(0%) saturate(47%) hue-rotate(128deg) brightness(90%)
    contrast(87%);
  margin-right: 5px;
}
.progressSpacer {
  height: 18.5px;
}
